import {Employee} from '@/components/employee'

export default {
  async find(): Promise<Array<Employee>> {
    return (await fetch('/api/employee', {
      headers: {
        'Accept': 'application/json'
      }
    })).json()
  },
  async delete(id: number): Promise<void> {
    await fetch(`/api/employee/${id}`, {
      method: 'DELETE'
    })
  },
  async update(employee: Employee): Promise<Employee> {
    return (await fetch(`/api/employee/${employee.id}`, {
      method: 'PUT',
      headers: {
        'Accept': 'application/json',
        'Content-Type': 'application/json'
      },
      body: JSON.stringify(employee)
    })).json()
  },
  async create(employee: any): Promise<Employee> {
    return (await fetch(`/api/employee`, {
      method: 'POST',
      headers: {
        'Accept': 'application/json',
        'Content-Type': 'application/json'
      },
      body: JSON.stringify(employee)
    })).json()
  }
}
