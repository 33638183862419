
import Vue from 'vue'
import {Employee} from '@/components/employee'
import EmployeeApi from '@/components/employee-api'

export default Vue.extend({
  computed: {
    modal(): any {
      return this.$refs.modal
    },
    readonly(): boolean {
      return this.mode === 'delete'
    },
    okTitle(): string {
      switch(this.mode) {
        case 'delete':
          return 'Delete'
        case 'create':
          return 'Create'
        case 'edit':
        default:
          return 'Save'
      }
    }
  },
  data() {
    return {
      employee: {} as any,
      mode: 'create'
    }
  },
  methods: {
    async create() {
      let updated = await EmployeeApi.create(this.employee)
      this.$emit('employee-created', updated)
    },
    async update() {
      let updated = await EmployeeApi.update(this.employee)
      this.$emit('employee-updated', updated)
    },
    async delete() {
      await EmployeeApi.delete(this.employee.id)
      this.$emit('employee-deleted')
    },
    submit() {
      switch(this.mode) {
        case 'delete':
          this.delete()
          break
        case 'edit':
          this.update()
          break
        case 'create':
          this.create()
          break
      }
    },
    cancel() {
      this.modal.hide()
    },
    $createItem() {
      this.mode = 'create'
      this.employee = {}
      this.modal.show()
    },
    $deleteItem(employee: Employee) {
      this.mode = 'delete'
      this.employee = JSON.parse(JSON.stringify(employee))
      this.modal.show()
    },
    $editItem(employee: Employee) {
      this.mode ='edit'
      this.employee = JSON.parse(JSON.stringify(employee))
      this.modal.show()
    }
  }
})
