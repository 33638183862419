
import Vue from "vue";
import EmployeeList from '@/components/EmployeeList.vue'
import App from '@/components/app'

export default Vue.extend({
  name: "App",
  components: {
    EmployeeList
  },
  computed: {
    appVersion(): string {
      return App.version()
    }
  }
});
