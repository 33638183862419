
import Vue from 'vue'
import { Employee } from '@/components/employee'
import EmployeeApi from './employee-api'
import EmployeeSearch from '@/components/EmployeeSearch.vue'
import EmployeeDialog from '@/components/EmployeeDialog.vue'

export default Vue.extend({
  components: {
    EmployeeDialog,
    EmployeeSearch
  },
  data() {
    return {
      loading: true,
      employees: [] as Array<Employee>,
      filter: undefined as string | undefined
    }
  },
  created() {
    this.reload()
  },
  computed: {
    fields(): Array<any> {
      return ['id', 'name', 'seasonNb', 'episodeNb', 'comment', 'actions']
    },
    filteredEmployees(): Array<Employee> {
      return this.lowerCaseFilter == '' ? this.employees : this.employees.filter(this.match)
    },
    lowerCaseFilter(): string {
      return this.filter?.toLowerCase() || ''
    },
    dialog(): any {
      return this.$refs.dialog
    }
  },
  methods: {
    employeeUpdated() {
      this.reload()
    },
    employeeDeleted() {
      this.reload()
    },
    async reload() {
      this.loading = true
      try {
        this.employees = await EmployeeApi.find()
      } finally {
        this.loading = false
      }
    },
    match(employee: Employee) {
      return employee.name.toLowerCase().includes(this.lowerCaseFilter) || employee.seasonNb.toLowerCase().includes(this.lowerCaseFilter)
    },
    deleteItem(employee: Employee) {
      this.dialog.$deleteItem(employee)
    },
    editItem(employee: Employee) {
      this.dialog.$editItem(employee)
    },
    newDeath() {
      this.dialog.$createItem()
    }
  }
})
